<template>
	<div class="flex items-center relative" role="none">
		<button
			id="provider-actions-dropdown-toggle"
			type="button"
			role="menuitem"
			aria-haspopup="true"
			aria-label="Provider actions"
			:aria-expanded="show.toString()"
			:tabindex="show ? '0' : '-1'"
			data-tooltip="More Actions"
			data-position="left"
			@blur.self.prevent="close"
			@click.prevent.stop="onDropdownClick"
		>
			<div class="btn-ripple"></div>
			<font-awesome-icon size="lg" :icon="['far', 'ellipsis-h']" />
		</button>
		<div
			v-show="show"
			aria-labelledby="provider-actions-dropdown-toggle"
			class="dropdown-menu dropdown-menu-md"
		>
			<a class="dropdown-item" @click.prevent="onResetPassword">
				Send Password Reset
			</a>
		</div>
	</div>
</template>
<script>
/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.onOutsideClick)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Emit the close event to any parent listeners.
		 *
		 * @return {void}
		 */
		close() {
			setTimeout(() => {
				if (this.show) {
					this.show = false
				}
			}, 200)
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		onDropdownClick() {
			this.show = !this.show

			this.show ? this.addEventListeners() : this.removeEventListeners()
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		onOutsideClick() {
			if (this.show) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.onOutsideClick)
		},

		/**
		 * Handle the reset password click event.
		 *
		 * @return {void}
		 */
		async onResetPassword() {
			if (!this.provider) {
				return
			}

			try {
				await this.$api.password().reset(this.provider.email_address)

				this.$alert.success('Password reset sent.')
			} catch (e) {
				this.$alert.response(e)
			}
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ProviderActionsDropdown',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The provider to be handled.
		 *
		 * @type {Object}
		 */
		provider: {
			type: Object,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Focus on the outter element when displaying the pop-up.
		 *
		 * @return {void}
		 */
		show() {
			if (this.show) {
				this.$el.focus()
			}
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			show: false,
		}
	},
}
</script>
