<template>
	<form
		class="user-password-section user-form"
		autocomplete="false"
		@submit.prevent="onFormSubmit"
	>
		<div class="flex justify-between align-middle">
			<h2 class="user-section-title">
				Password Reset
			</h2>
		</div>
		<div class="form-group">
			<label for="password" class="form-label">
				Password
			</label>
			<input
				id="password"
				v-model="password"
				type="password"
				name="password"
				class="form-input"
				placeholder="Password"
			/>
		</div>
		<div class="form-group">
			<label for="passwordConfirm" class="form-label">
				Confirm Password
			</label>
			<input
				id="passwordConfirm"
				v-model="confirmPassword"
				type="password"
				name="passwordConfirm"
				class="form-input"
				placeholder="Confirm Password"
			/>
		</div>
		<div v-if="!isValidPassword" class="form-group">
			<span v-if="!hasMinimumLength" class="text-red-500">
				The password must have at least 8 characters</span
			>
			<span v-if="!isValidPassword" class="text-red-500">
				The password confirmation does not match</span
			>
		</div>
		<div class="flex justify-end">
			<button
				type="submit"
				:disabled="!isValidPassword"
				class="btn btn-outlined ml-2"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon class="btn-icon" :icon="['far', 'edit']" />
				<span class="btn-label">
					Reset
				</span>
			</button>
		</div>
	</form>
</template>

<script>
import { mapGetters } from 'vuex'
/**
 * The minimum number of characters the password must have.
 *
 * @type {Number}
 */
const MIN_PASSWORD_LENGTH = 8

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the provider from the store.
		 *
		 * @return  {Object}
		 */
		provider() {
			return this.find(this.$route.params.id)
		},

		/**
		 * Determine if the password has the minimum lenght.
		 *
		 * @return {Boolean}
		 */
		hasMinimumLength() {
			return this.password.length >= MIN_PASSWORD_LENGTH
		},

		/**
		 * Determine is the password is valid.
		 *
		 * @return {Boolean}
		 */
		isValidPassword() {
			if (this.password.length === 0) {
				return true
			}

			return (
				this.password === this.confirmPassword && this.hasMinimumLength
			)
		},

		...mapGetters({
			find: 'providers/find',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			if (!this.provider) {
				return
			}

			try {
				const response = await this.$api
					.users()
					.updatePassword(
						this.provider?.user_id,
						this.password,
						this.confirmPassword
					)

				this.$alert.response(response)
			} catch (e) {
				this.$alert.response(e)
			}
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ProviderPasswordForm',

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			password: '',
			confirmPassword: '',
		}
	},
}
</script>
