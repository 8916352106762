<template>
	<div class="providers">
		<router-link
			:to="{ name: 'app.providers' }"
			class="btn mr-auto"
			data-cy="back-button"
		>
			<font-awesome-icon
				fixed-width
				class="btn-icon"
				:icon="['far', 'arrow-to-left']"
				aria-hidden="true"
			/>
			<div class="btn-ripple"></div>
			<span class="btn-label">Back</span>
		</router-link>
		<div class="provider-header">
			<h1 class="provider-name-title">
				{{ fullName }}
			</h1>
			<provider-actions-dropdown
				v-if="isInternalProvider"
				:provider="provider"
			/>
		</div>
		<div class="provider-forms-container">
			<form class="provider-container" @submit.prevent="onSubmit">
				<div class="provider-left">
					<div class="provider-card">
						<div class="form-group">
							<label for="title" class="form-label">
								Title
							</label>
							<input
								id="title"
								v-model="form.title"
								type="text"
								name="title"
								class="form-input"
								placeholder="Doctor, NP, etc."
							/>
						</div>
						<div class="form-group">
							<label for="email" class="form-label">
								Notification Email
							</label>
							<input
								id="email"
								v-model="form.email_address"
								required
								type="text"
								name="email"
								class="form-input"
								placeholder="Email Address"
							/>
						</div>

						<div class="form-group">
							<label for="phone" class="form-label">
								Notification Phone
							</label>
							<input
								id="phone"
								v-model="form.phone_number"
								required
								type="text"
								name="phone"
								placeholder="Phone Number"
								class="form-input"
							/>
						</div>
						<div class="form-group">
							<label for="phoneSecondary" class="form-label">
								Notification Secondary Phone
							</label>
							<input
								id="phoneSecondary"
								v-model="form.secondary_phone_number"
								type="text"
								name="phoneSecondary"
								class="form-input"
								placeholder="Phone Number"
							/>
						</div>
						<div class="form-group">
							<label for="pagerEmail" class="form-label">
								Pager Email
							</label>
							<input
								id="pagerEmail"
								v-model="form.pager_email_address"
								type="text"
								name="pagerEmail"
								class="form-input"
								placeholder="Email Address"
							/>
						</div>
						<div class="form-group">
							<label for="pagerPhone" class="form-label">
								Pager Phone
							</label>
							<input
								id="pagerPhone"
								v-model="form.pager_phone_number"
								type="text"
								name="pagerPhone"
								class="form-input"
								placeholder="Phone Number"
							/>
						</div>
						<div class="form-group">
							<div class="form-label">
								Schedule Color
							</div>
							<div class="color-picker">
								<label
									v-for="(color, $index) in colors"
									:key="$index"
									:for="`${color.name}-radio`"
									class="color-picker-option"
									:class="{
										active: form.color === color.name,
									}"
								>
									<div
										class="color-picker-option-outter"
										:style="{ 'border-color': color.code }"
									>
										<span
											class="color-picker-option-inner"
											:style="{ background: color.code }"
										></span>
									</div>
									<input
										:id="`${color.name}-radio`"
										v-model="form.color"
										class="color-picker-input"
										type="radio"
										name="color"
										:value="color.name"
									/>
									{{ color.label }}
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="provider-right">
					<div class="provider-card">
						<h2 class="provider-form-title">Notifications</h2>
						<div class="form-group">
							<div class="form-check">
								<input
									id="patient-available-checkbox"
									v-model="form.patient_available"
									type="checkbox"
									class="form-check-input"
									name="patient-available-checkbox"
								/>
								<label
									for="patient-available-checkbox"
									class="form-check-label"
								>
									Available for Patients
								</label>
							</div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<input
									id="push-notifications-checkbox"
									v-model="form.allow_mobile_notifications"
									type="checkbox"
									class="form-check-input"
									name="push-notifications-checkbox"
								/>
								<label
									for="push-notifications-checkbox"
									class="form-check-label"
								>
									Mobile (Push) Notifications
								</label>
							</div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<input
									id="email-notifications-checkbox"
									v-model="form.allow_email_notifications"
									type="checkbox"
									class="form-check-input"
									name="email-notifications-checkbox"
								/>
								<label
									for="email-notifications-checkbox"
									class="form-check-label"
								>
									Email Notifications
								</label>
							</div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<input
									id="voice-notifications-checkbox"
									v-model="form.allow_voice_notifications"
									type="checkbox"
									class="form-check-input"
									name="voice-notifications-checkbox"
								/>
								<label
									for="voice-notifications-checkbox"
									class="form-check-label"
								>
									Voice Notifications
								</label>
							</div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<input
									id="secondary-voice-notifications-checkbox"
									v-model="
										form.allow_secondary_voice_notifications
									"
									type="checkbox"
									class="form-check-input"
									name="secondary-voice-notifications-checkbox"
								/>
								<label
									for="secondary-voice-notifications-checkbox"
									class="form-check-label"
								>
									Secondary Voice Notifications
								</label>
							</div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<input
									id="sms-notifications-checkbox"
									v-model="form.allow_sms_notifications"
									type="checkbox"
									class="form-check-input"
									name="sms-notifications-checkbox"
								/>
								<label
									for="sms-notifications-checkbox"
									class="form-check-label"
								>
									SMS (Text) Notifications
								</label>
							</div>
						</div>
						<div class="form-group">
							<div class="form-check">
								<input
									id="secondary-sms-notifications-checkbox"
									v-model="
										form.allow_secondary_sms_notifications
									"
									type="checkbox"
									class="form-check-input"
									name="secondary-sms-notifications-checkbox"
								/>
								<label
									for="secondary-sms-notifications-checkbox"
									class="form-check-label"
								>
									Secondary SMS (Text) Notifications
								</label>
							</div>
						</div>

						<div class="form-group">
							<div class="form-check">
								<input
									id="pager-notifications-checkbox"
									v-model="form.allow_pager_notifications"
									type="checkbox"
									class="form-check-input"
									name="pager-notifications-checkbox"
								/>
								<label
									for="pager-notifications-checkbox"
									class="form-check-label"
								>
									Pager Notifications
								</label>
							</div>
						</div>
						<div class="form-group flex-row justify-end">
							<button type="submit" class="btn btn-outlined ml-2">
								<div class="btn-ripple"></div>
								<font-awesome-icon
									class="btn-icon"
									:icon="['far', 'save']"
								/>
								<span class="btn-label">
									Save
								</span>
							</button>
						</div>
					</div>
				</div>
			</form>
			<provider-password-form
				v-if="hasPartnerAdminAccess && isInternalProvider"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import colors from '@/config/colors'
import ProviderPasswordForm from '@/components/Forms/ProviderPasswordForm'
import ProviderActionsDropdown from '@/components/ProviderActionsDropdown.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ProviderPasswordForm,
		ProviderActionsDropdown,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the provider's full name.
		 *
		 * @return {String}
		 */
		fullName() {
			if (!this.provider) {
				return ''
			}

			if (!this.provider.user) {
				return this.provider.full_name
			}

			const { first_name, last_name } = this.provider.user

			return `${first_name} ${last_name}`
		},

		/**
		 * Determine if the current provider is internal.
		 *
		 * @return {Boolean}
		 */
		isInternalProvider() {
			return !!(this?.provider?.type === 'internal')
		},

		/**
		 * Get the selected provider.
		 *
		 * @return {Object}
		 */
		provider() {
			return this.find(this.$route.params.id)
		},

		...mapGetters('providers', ['find']),
		...mapGetters({
			hasPartnerAdminAccess: 'auth/hasPartnerAdminAccess',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the form field names/keys.
		 *
		 * @return {Array}
		 */
		fields() {
			return Object.keys(this.form)
		},

		/**
		 * Fill the form with the provider's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (!this.provider) {
				return
			}

			this.fields().forEach(key => (this.form[key] = this.provider[key]))
		},

		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async onSubmit() {
			try {
				const response = await this.$api
					.providers()
					.update(this.provider.id, this.form)

				const provider = response.get('provider')

				provider.partner_id = provider?.partner.id

				this.$store.dispatch('providers/add', provider)

				this.$alert.success('providers.success')
			} catch (e) {
				this.$alert.error('providers.error')
			}
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Edit',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for changes.
		 */
		provider: {
			immediate: true,
			handler: 'fillForm',
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			colors,
			form: {
				allow_email_notifications: false,
				allow_mobile_notifications: false,
				allow_pager_notifications: false,
				allow_secondary_sms_notifications: false,
				allow_secondary_voice_notifications: false,
				allow_sms_notifications: false,
				allow_voice_notifications: false,
				color: '',
				email_address: '',
				pager_email_address: '',
				pager_phone_number: '',
				patient_available: false,
				phone_number: '',
				secondary_phone_number: '',
				title: '',
			},
		}
	},
}
</script>
